import Navbar from "./components/Navbar";
import CustomerTasks from "./components/customerTasks/CustomerTasks";
import CustomerTask from "./components/customerTasks/CustomerTask";
import UnpaidInvoices from "./components/payments/UnpaidInvoices";
import PaidInvoices from "./components/payments/PaidInvoices";
import {Route, Switch, BrowserRouter as Router, Redirect} from "react-router-dom";
import {Fragment, useEffect, useState} from "react";
import {AuthContext} from "./contexts/AuthContext";
import {JobFilterContext} from "./contexts/JobFilterContext";
import Login from "./components/auth/Login";
import IndirectCosts from "./components/dashboard/IndirectCosts";
import ProfileSettings from "./components/profile/ProfileSettings";
import Overview from "./components/dashboard/Overview";
import logo from "./OGimageFB-e1610124937181.png";
import {useHistory} from "react-router-dom";
import Container from "./components/auth/Container";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/Forgot";

function App() {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(null);
    const [workTypes, setWorkTypes] = useState([]);
    const [languagePairs, setLanguagePairs] = useState([]);
    const [ws, setWs] = useState(null);
    const history = useHistory();

    useEffect(() => {
        (async () => {
            const tokenFromLocalStorage = localStorage.getItem('token');
            if (tokenFromLocalStorage) {
                try {
                    const response = await fetchUser(tokenFromLocalStorage);
                    if (!response.error && response.user) {
                        setUser(response.user);
                        setToken(tokenFromLocalStorage);
                    }
                }
                catch (e) {
                    console.error(e);
                }
            }
        })();
    }, []);

    useEffect(() => {
        let isMounted = true;
        if (token) {
            (async () => {
                try {
                    const jsonWorkTypes = await fetchWorkTypes();
                    if (isMounted) {
                        if (!jsonWorkTypes.error) {
                            setWorkTypes(jsonWorkTypes.workTypes);
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                }
                try {
                    const jsonLanguagePairs = await fetchLanguagePairs();
                    if (isMounted) {
                        if (!jsonLanguagePairs.error) {
                            setLanguagePairs(jsonLanguagePairs.languagePairs);
                        }
                    }
                }
                catch (e) {
                    console.error(e);
                }
            })();

            try {
                const conn  = new WebSocket(process.env.REACT_APP_wsEndPoint + '?token=' + token);
                setWs(conn);

                conn.addEventListener("open", (evt) => {
                    console.log("Successfully opened ws connection.");
                });
                conn.addEventListener("error", (err) => {
                    console.error(err);
                });
                conn.addEventListener("message", (msg) => {
                    try {
                        const json = JSON.parse(msg.data);
                        if (json.method === "RECEIVE_MESSAGE") {
                            const notification = new Notification('New message received from freelancer', {
                                body: json.message.body,
                                icon: logo
                            });
                            notification.onclick = (evt) => {
                                const invoiceId = json.message.invoiceId;
                                if (json.paid) {
                                    history.push('/payments/paidInvoices?invoiceId=' + invoiceId);
                                }
                                else {
                                    history.push('/payments/unpaidInvoices?invoiceId=' + invoiceId);
                                }
                            };
                        }

                    }
                    catch (e) {
                        console.error(e);
                    }
                });
                conn.addEventListener("close", (evt) => {
                    console.log("Connection closed");
                });
            }
            catch (e) {
                console.error(e);
            }
        }
        return () => {
            isMounted = false;
        }
    }, [token]);

    async function fetchUser(token) {
        const response = await fetch(process.env.REACT_APP_endPoint + '/v1/auth', {
            method: "GET",
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });

        return response.json();
    }

    async function fetchWorkTypes() {
        const response = await fetch(process.env.REACT_APP_endPoint + '/v1/workTypes', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        return response.json();
    }

    async function fetchLanguagePairs() {
        const response = await fetch(process.env.REACT_APP_endPoint + '/v1/languagePairs', {
            headers: {
                'Authorization': 'Bearer ' + token
            }
        });
        return response.json();
    }

    return (
        <AuthContext.Provider value={{
            user: user,
            token: token,
            setToken: setToken,
            setUser: setUser,
            ws
        }}>
            <div className={"d-flex flex-column min-vh-100 bg-light"}>
                {token ? (<Fragment>
                    <JobFilterContext.Provider value={{
                        workTypes,
                        languagePairs
                    }}>
                        <Navbar />
                        <Switch>
                            <Route path={'/customerTasks'} exact={true}>
                                <CustomerTasks />
                            </Route>
                            <Route path={'/customerTasks/:customerTaskId'} exact={true}>
                                <CustomerTask />
                            </Route>
                            <Route path={'/payments/unpaidInvoices'} exact={true}>
                                <UnpaidInvoices />
                            </Route>
                            <Route path={'/payments/paidInvoices'} exact={true}>
                                <PaidInvoices />
                            </Route>
                            <Route path={'/dashboard/overview'} exact={true}>
                                <Overview />
                            </Route>
                            <Route path={'/dashboard/indirectCosts'} exact={true}>
                                <IndirectCosts />
                            </Route>
                            <Route path={'/profile/settings'} exact={true}>
                                <ProfileSettings />
                            </Route>
                            <Route path={'*'}>
                                <Redirect to={'/dashboard/overview'}/>
                            </Route>
                        </Switch>
                    </JobFilterContext.Provider>
                </Fragment>) :
                <Container>
                    <Switch>
                        <Route path={'/signIn'} exact={true}>
                            <Login />
                        </Route>
                        <Route path={'/resetPassword'} exact={true}>
                            <ResetPassword />
                        </Route>
                        <Route path={'/forgotPassword'} exact={true}>
                            <ForgotPassword />
                        </Route>
                        <Route path={'*'}>
                            <Redirect to={'/signIn'}/>
                        </Route>
                    </Switch>
                </Container>
                }
            </div>
        </AuthContext.Provider>
    );
}

export default App;
