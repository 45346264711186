import Search from "../Search";
import JobFilters from "../JobFilters";
import Pagination from "../Pagination";
import {SortContext} from "../../contexts/SortContext";
import SortableHeader from "../SortableHeader";
import {Fragment, useContext, useEffect, useRef, useState} from "react";
import {NavLink} from "react-router-dom";
import {AuthContext} from "../../contexts/AuthContext";
import {JobFilterContext} from "../../contexts/JobFilterContext";
import Error from "../Alerts/Error";
import Spinner from "../Spinner";
import JobRow from "../JobRow";
import SmallSpinner from "../SmallSpinner";

function IndirectCosts() {
	const [sortBy, setSortBy] = useState("name");
	const [asc, setAsc] = useState(false);
	const [desc, setDesc] = useState(false);
	const [languagePair, setLanguagePair] = useState("");
	const [workType, setWorkType] = useState([]);
	const [search, setSearch] = useState("");
	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(2);
	const [jobs, setJobs] = useState([]);
	const [quantity, setQuantity] = useState(20);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [invoiceStatus, setInvoiceStatus] = useState("");
	const [creationDateFrom, setCreationDateFrom] = useState('');
	const [creationDateTo, setCreationDateTo] = useState('');
	const [invoiceDateFrom, setInvoiceDateFrom] = useState('');
	const [invoiceDateTo, setInvoiceDateTo] = useState('');
	const [totalValue, setTotalValue] = useState(0);
	const [exportLoading, setExportLoading] = useState(false);

	const {token, setToken, setUser} = useContext(AuthContext);
	const {workTypes} = useContext(JobFilterContext);

	const abortController = useRef();

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoading(true);
			setError('');
			try {
				const jsonMaxPage = await fetchMaxPage();
				if (isMounted) {
					if (jsonMaxPage.error) {
						setError(jsonMaxPage.error);
					}
					else {
						setMaxPage(jsonMaxPage.maxPage);
						if (page > jsonMaxPage.maxPage) {
							setPage(jsonMaxPage.maxPage);
						}
					}
				}
				const jsonJobs = await fetchJobs();
				if (isMounted) {
					if (jsonJobs.error) {
						setError(jsonJobs.error);
					}
					else {
						setJobs(jsonJobs.jobs);
						setTotalValue(jsonJobs.totalValue);
					}
				}
			}
			catch (e) {
				console.error(e);
				if (isMounted) {
					setError('Failed to reach the server.');
				}
			}
			finally {
				if (isMounted) {
					setLoading(false);
				}
			}
		})();


		return () => {
			isMounted = false;
		}
	}, [page, quantity, languagePair, invoiceStatus, creationDateFrom, creationDateTo, invoiceDateFrom, invoiceDateTo, search, workType, sortBy, asc, desc]);

	async function fetchJobs() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (languagePair) {
			params.append('languagePair', languagePair);
		}
		if (workType) {
			params.append('workType', workType);
		}
		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (invoiceStatus) {
			params.append('invoiceStatus', invoiceStatus);
		}
		params.append('page', page);
		params.append('type', 'indirect');

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/jobs?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function fetchMaxPage() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (languagePair) {
			params.append('languagePair', languagePair);
		}
		if (workType) {
			params.append('workType', workType);
		}
		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (invoiceStatus) {
			params.append('invoiceStatus', invoiceStatus);
		}
		params.append('type', 'indirect');

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/jobs/maxPage?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function exportJobs() {
		try {
			setExportLoading(true);
			let params = new URLSearchParams();

			if (search) {
				params.append('search', search);
			}
			if (languagePair) {
				params.append('languagePair', languagePair);
			}
			if (workType) {
				params.append('workType', workType);
			}
			if (creationDateFrom) {
				params.append('creationDateFrom', creationDateFrom);
			}
			if (creationDateTo) {
				params.append('creationDateTo', creationDateTo);
			}
			if (invoiceDateFrom) {
				params.append('invoiceDateFrom', invoiceDateFrom);
			}
			if (invoiceDateTo) {
				params.append('invoiceDateTo', invoiceDateTo);
			}
			if (asc || desc) {
				params.append('asc', asc);
				params.append('desc', desc);
				params.append('sortBy', sortBy);
			}
			if (invoiceStatus) {
				params.append('invoiceStatus', invoiceStatus);
			}
			params.append('type', 'indirect');

			const controller = new AbortController();
			const signal = controller.signal;
			abortController.current = controller;

			const response = await fetch(process.env.REACT_APP_endPoint + '/v1/jobs/exportJobs?' + params.toString(), {
				method: "GET",
				headers: {
					'Authorization': 'Bearer ' + token
				},
				signal
			});

			let data = [];
			const reader = response.body.getReader();
			reader.read().then(processData);

			function processData({done, value}) {
				try {
					if (done) {
						const uri = URL.createObjectURL(new Blob(data));
						let link = document.createElement("a");
						link.download = "indirect-jobs.tsv";
						link.href = uri;
						link.click();
						link.remove();
						setExportLoading(false);
					}
					else {
						data.push(value);
						return reader.read().then(processData);
					}
				}
				catch (e) {
					console.error(e);
				}
			}
		}
		catch (e) {
			console.error(e);
			setError('Failed to export these invoices.');
			setExportLoading(false);
		}
	}

	return (
		<div className={"m-2 border p-4 bg-white shadow-sm"}>
			{error && <Error message={error} /> }

			<Search placeHolder={"Search jobs on name, value, freelancer"} search={search} setSearch={setSearch}/>

			<div className={"mt-4"}>
				<JobFilters languagePair={languagePair} setLanguagePair={setLanguagePair} setWorkType={setWorkType}
							workType={workType} workTypes={workTypes} setInvoiceStatus={setInvoiceStatus} invoiceStatus={invoiceStatus}
							creationDateFrom={creationDateFrom} setCreationDateFrom={setCreationDateFrom} setCreationDateTo={setCreationDateTo} creationDateTo={creationDateTo}
							invoiceDateFrom={invoiceDateFrom} setInvoiceDateFrom={setInvoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateTo={setInvoiceDateTo}/>
			</div>

			<hr />
			<div className={"mt-4"}>
				<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
			</div>
			<button className={"btn btn-secondary mt-4"} onClick={exportJobs} disabled={exportLoading}>Export {exportLoading && <SmallSpinner />} jobs</button>

			<SortContext.Provider value={{
				sortBy: sortBy,
				asc: asc,
				desc: desc,
				setSortBy: setSortBy,
				setAsc: setAsc,
				setDesc: setDesc
			}}>
				<table className={"table table-striped m-0 my-4 border shadow-sm"}>
					<caption className={"caption-top"}>Indirect jobs ({loading ? <SmallSpinner />: <Fragment>&euro;{totalValue.toLocaleString()}</Fragment>})</caption>
					<thead className={"table-secondary"}>
					<tr>
						<SortableHeader columnName={"name"} text={"Name"}/>
						<SortableHeader columnName={"workType"} text={"Work type"}/>
						<SortableHeader columnName={"value"} text={"Value"}/>
						<SortableHeader columnName={"freelancer"} text={"Freelancer"}/>
						<th>Description</th>
						<SortableHeader columnName={"invoiceDate"} text={"Invoice date"}/>
						<SortableHeader columnName={"deliveryDate"} text={"Delivery date"}/>
					</tr>
					</thead>
					<tbody>
					{loading ? <tr><td colSpan={11}>
							<div className="d-flex justify-content-center">
								<Spinner />
							</div>
						</td></tr> :
						jobs.map(value => {
							return <JobRow key={value.id} job={value}/>;
						})
					}
					</tbody>
				</table>
			</SortContext.Provider>
			<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
		</div>
	);
}

export default IndirectCosts;
