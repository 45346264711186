import {useContext, useEffect, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import Spinner from "../Spinner";
import Error from "../Alerts/Error";
import QrCode from "qrcode";
import base32Encode from 'base32-encode'
import PushNotifications from "./settings/PushNotifications";
import Totp from "./settings/Totp";

function ProfileSettings() {


	return (
		<div className={"m-2 border p-3 bg-white shadow-sm"}>
			<Totp />
			<hr />
			<PushNotifications />
		</div>
	);
}

export default ProfileSettings;