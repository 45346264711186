import {AuthContext} from "../../../contexts/AuthContext";
import QrCode from "qrcode";
import base32Encode from "base32-encode";
import Error from "../../Alerts/Error";
import {useContext, useEffect, useState} from "react";
import {verifyTotp} from "../../../util/functions";
import Success from "../../Alerts/Success";
import Spinner from "../../Spinner";
import SmallSpinner from "../../SmallSpinner";

export default function Totp() {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState(null);
    const [qrCode, setQrCode] = useState('');
    const [totpSecret, setTotpSecret] = useState(null);
    const {user, token} = useContext(AuthContext);
    const [understand, setUnderstand] = useState(false);
    const [totp, setTotp] = useState("");

    async function generateTotp() {
        setError(null);

        try {
            const totpSecret = Math.random().toString(36).slice(2);
            const encoder = new TextEncoder();
            setQrCode(await QrCode.toDataURL('otpauth://totp/' + 'DAP:' + user.email + "?secret=" + base32Encode(encoder.encode(totpSecret), 'RFC4648')));
            setTotpSecret(totpSecret);
        }
        catch (e) {
            console.error(e);
            setError("Failed to generate TOTP.");
        }
    }

    async function onSubmit(evt) {
        evt.preventDefault();
        try {
            setLoading(true);
            setError(null);
            setSuccess(null);

            //validate TOTP
            if (!verifyTotp(totp, totpSecret)) {
                return setError("Your TOTP is not correct.");
            }

            const response = await fetch(process.env.REACT_APP_endPoint + '/v1/auth/totpSecret', {
                method: "PUT",
                headers: {
                    'Content-Type': "application/json",
                    'Authorization': 'Bearer ' + token
                },
                body: JSON.stringify({totpSecret})
            });

            if (response.status === 204) {
                setQrCode(null);
                setTotpSecret(null);
                setUnderstand(false);
                setSuccess("Successfully saved this TOTP, the next time you login you will have to enter it.");
            }
            else {
                const json = await response.json();
                if (json.error) {
                    setError(json.error);
                }
            }
        }
        catch (e) {
            console.error(e);
            setError('Failed to reach the server.');
        }
        finally {
            setLoading(false);
        }
    }

    return (
        <>
            <h3>TOTP setup</h3>
            {error && <Error message={error} /> }
            {success && <Success message={success} /> }
            <button className={"btn btn-primary mb-2"} onClick={generateTotp}>{qrCode && 'Re-'}Generate TOTP key</button>

            {qrCode && <div><img src={qrCode}  alt={"TOTP qr code"}/></div>}

            {qrCode &&
                <form onSubmit={onSubmit} className={"mt-3"}>
                    <h4>How it works</h4>
                    <p>You will need to install an app like Google Authenticator on your smartphone. </p>
                    <p>Press on the button “Generate TOTP..” - a QR code will appear. </p>
                    <p>You need to scan this QR code with the app. A good idea might be to also save the qr code as an image, in case something would happen to your phone.</p>
                    <p>Then click SAVE - from now on you will need to enter the number generated by the mobile app each time you log in to this profile.</p>
                    <p>If you lose your phone, you might not be able to access this account anymore, unless you have the qr code saved and you re-scan it.</p>
                    <div className={"mb-3"} style={{width: '100px'}}>
                        <label htmlFor="totp" className={"form-label"}>TOTP:</label>
                        <input type="number" minLength={6} maxLength={6} className={"form-control"} id={"totp"} value={totp} required={true} onChange={(evt) => {
                            setTotp(evt.target.value);
                        }} placeholder={"123456"}/>
                    </div>
                    <div className="form-check">
                        <label className="form-check-label" htmlFor="understand">
                            <input required={true} className="form-check-input" type="checkbox" value="" id="understand" checked={understand} onChange={(evt) => {
                                setUnderstand(evt.target.checked);
                            }}/>

                            I understand how this works.
                        </label>
                    </div>
                    <button className={"btn btn-danger mt-3"} type={"submit"} disabled={!understand || loading}>{loading && <SmallSpinner />} SAVE/OVERWRITE</button>
                </form>
            }
        </>
    );
}