import SortableHeader from "../SortableHeader";
import {useState, useContext, useEffect, useRef, Fragment} from "react";
import {SortContext} from "../../contexts/SortContext";
import Pagination from "../Pagination";
import Search from "../Search";
import PaidInvoicesFilters from "./PaidInvoicesFilters";
import {AuthContext} from "../../contexts/AuthContext";
import Error from "../Alerts/Error";
import Spinner from "../Spinner";
import PaidInvoiceRow from "./PaidInvoiceRow";
import SmallSpinner from "../SmallSpinner";
import Messages from "../messages/Messages";
import {useHistory, useLocation} from "react-router-dom";

function PaidInvoices() {
	const [sortBy, setSortBy] = useState("freelancer");
	const [asc, setAsc] = useState(false);
	const [desc, setDesc] = useState(false);
	const [page, setPage] = useState(0);
	const [maxPage, setMaxPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [quantity, setQuantity] = useState(20);
	const [invoices, setInvoices] = useState([]);
	const [paymentDateFrom, setPaymentDateFrom] = useState('');
	const [paymentDateTo, setPaymentDateTo] = useState('');
	const [search, setSearch] = useState('');
	const [type, setType] = useState("all");
	const [total, setTotal] = useState([]);
	const [srcDoc, setSrcDoc] = useState("");
	const [selectedInvoice, setSelectedInvoice] = useState(null);
	const [exportLoading, setExportLoading] = useState(false);
	const [notExported, setNotExported] = useState(false);
	const [initialRender, setInitialRender] = useState(true);
	const [paymentMethod, setPaymentMethod] = useState("");
	const abortController = useRef();
	const {token, setToken, setUser} = useContext(AuthContext);
	const location = useLocation();
	const history = useHistory();

	useEffect(() => {
		setInitialRender(false);
		return () => {
			abortController.current && abortController.current.abort();
		}
	}, []);

	useEffect(() => {
		(async () => {
			try {
				const params = new URLSearchParams(location.search);
				const invoiceId = params.get('invoiceId');

				if (invoiceId) {
					if (initialRender) {
						const {page: pageToSet} = await getPageFor(invoiceId);
						if (pageToSet !== null) {
							setPage(pageToSet);
							setAsc(false);
							setDesc(false);
							setPaymentDateFrom('');
							setPaymentDateTo('');
							setSearch('');
							setType('all');
						}
					}
					setSelectedInvoice(parseInt(invoiceId));
				}
			}
			catch (e) {
				console.error(e);
			}

		})();

	}, [location.search]);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoading(true);
			setError('');

			try {
				const jsonMaxPage = await fetchMaxPage();
				if (isMounted) {
					if (jsonMaxPage.error) {
						setError(jsonMaxPage.error);
					}
					else {
						setMaxPage(jsonMaxPage.maxPage);
						if (page > jsonMaxPage.maxPage) {
							setPage(jsonMaxPage.maxPage);
						}
					}
				}
				const jsonInvoices = await fetchInvoices();
				if (isMounted) {
					if (jsonInvoices.error) {
						setError(jsonInvoices.error);
					}
					else {
						setInvoices(jsonInvoices.invoices);
						setTotal(jsonInvoices.total);
					}
				}
			}
			catch (e) {
				console.error(e);
				if (isMounted) {
					setError('Failed to reach the server.');
				}
			}
			finally {
				if (isMounted) {
					setLoading(false);
				}
			}
		})();
		return () => {
			isMounted = false;
		}
	}, [page, quantity, type, paymentDateFrom, paymentDateTo, search, paymentMethod, sortBy, asc, desc]);

	useEffect(() => {
		const invoice = invoices.find(value => value.id === selectedInvoice);
		if (invoice) {
			setSrcDoc(invoice.html || "");
		}
		else {
			setSrcDoc("");
		}
		function onKeyDown(evt) {
			const currentIndex = invoices.findIndex(value => value.id === selectedInvoice);
			if (evt.key === "ArrowDown") {
				if (invoices[currentIndex + 1]) {
					history.push({
						pathname: "/payments/paidInvoices",
						search: "?invoiceId=" + invoices[currentIndex + 1].id
					});
				}
			}
			else if (evt.key === "ArrowUp") {
				if (invoices[currentIndex - 1]) {
					history.push({
						pathname: "/payments/paidInvoices",
						search: "?invoiceId=" + invoices[currentIndex - 1].id
					});
				}
			}
		}

		window.addEventListener("keydown", onKeyDown);

		return () => {
			window.removeEventListener("keydown", onKeyDown);
		}
	}, [selectedInvoice, JSON.stringify(invoices)]);

	async function fetchInvoices() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (type) {
			params.append('type', type);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (paymentDateFrom) {
			params.append('paymentDateFrom', paymentDateFrom);
		}
		if (paymentDateTo) {
			params.append('paymentDateTo', paymentDateTo);
		}
		if (paymentMethod) {
			params.append('paymentMethod', paymentMethod);
		}
		params.append('paid', true);

		params.append('page', page);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/invoices?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function fetchMaxPage() {
		let params = new URLSearchParams();

		if (search) {
			params.append('search', search);
		}
		if (type) {
			params.append('type', type);
		}
		if (asc || desc) {
			params.append('asc', asc);
			params.append('desc', desc);
			params.append('sortBy', sortBy);
		}
		if (quantity) {
			params.append('quantity', quantity);
		}
		if (paymentDateFrom) {
			params.append('paymentDateFrom', paymentDateFrom);
		}
		if (paymentDateTo) {
			params.append('paymentDateTo', paymentDateTo);
		}
		if (paymentMethod) {
			params.append('paymentMethod', paymentMethod);
		}

		params.append('paid', true);


		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/invoices/maxPage?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function exportInvoices() {
		try {
			setExportLoading(true);
			let params = new URLSearchParams();

			if (search) {
				params.append('search', search);
			}
			if (type) {
				params.append('type', type);
			}
			if (paymentDateFrom) {
				params.append('paymentDateFrom', paymentDateFrom);
			}
			if (paymentDateTo) {
				params.append('paymentDateTo', paymentDateTo);
			}
			if (notExported) {
				params.append('notExported', true);
			}
			if (paymentMethod) {
				params.append('paymentMethod', paymentMethod);
			}

			params.append('paid', true);

			const controller = new AbortController();
			const signal = controller.signal;
			abortController.current = controller;

			const response = await fetch(process.env.REACT_APP_endPoint + '/v1/invoices/exportInvoices?' + params.toString(), {
				method: "GET",
				headers: {
					'Authorization': 'Bearer ' + token
				},
				signal
			});

			let data = [];
			const reader = response.body.getReader();
			const chunk = await reader.read();
			await processData(chunk);

			async function processData({done, value}) {
				try {
					if (done) {
						const uri = URL.createObjectURL(new Blob(data));
						let link = document.createElement("a");
						link.download = "invoicesExport.zip";
						link.href = uri;
						link.click();
						link.remove();
					}
					else {
						data.push(value);
						await processData(await reader.read());
					}
				}
				catch (e) {
					console.error(e);
				}
			}
		}
		catch (e) {
			console.error(e);
			setError('Failed to export these invoices.');
		}
		finally {
			setExportLoading(false);
		}
	}

	async function getPageFor(invoiceId) {
		let params = new URLSearchParams();
		params.append('paid', true);
		if (quantity) {
			params.append('quantity', quantity);
		}

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/invoices/' + invoiceId + '/page?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<SortContext.Provider value={{
			sortBy: sortBy,
			asc: asc,
			desc: desc,
			setSortBy: setSortBy,
			setAsc: setAsc,
			setDesc: setDesc
		}}>
			<div className={"m-2 border p-4 bg-white shadow-sm"}>
				{error && <Error message={error} /> }

				<div className="row">
					<div className="col">
						<Search placeHolder={"Search invoices on name, email, value, VAT, company name, iban, swift/bic, transferwise email, paypal"} search={search} setSearch={setSearch}/>
						<div className={"mt-4"}>
							<PaidInvoicesFilters type={type} setType={setType} paymentDateFrom={paymentDateFrom}
												 setPaymentDateFrom={setPaymentDateFrom} paymentDateTo={paymentDateTo} setPaymentDateTo={setPaymentDateTo}
												 paymentMethod={paymentMethod} setPaymentMethod={setPaymentMethod}/>
						</div>
						<div className="mt-4">
							<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
						</div>

						<div className="my-4">
							<table className={"table table-striped m-0 border shadow-sm"}>
								<thead className={"table-secondary"}>
								<tr>
									<SortableHeader columnName={"fullName"} text={"Full name"}/>
									<SortableHeader columnName={"email"} text={"Email"}/>
									<SortableHeader columnName={"stamp"} text={"Creation date"}/>
									<SortableHeader columnName={"paymentDate"} text={"Payment date"}/>
									<SortableHeader columnName={"value"} text={"Value"}/>
								</tr>
								</thead>
								<tbody>
								{loading ? <tr><td colSpan={5}>
										<div className="d-flex justify-content-center">
											<Spinner />
										</div>
									</td></tr> :
									invoices.map(value => {
										return <PaidInvoiceRow key={(value.id + value.isLsp.toString())} invoice={value} selectedInvoice={selectedInvoice}/>;
									})
								}
								</tbody>
								<tbody>
								{loading ? <tr><td colSpan={5}>
										<div className="d-flex justify-content-center">
											<Spinner />
										</div>
									</td></tr> :
									<Fragment>
										<tr>
											<td colSpan={4}>Total value of invoices paid:</td>
											<td>&euro;{total.reduce((a, b) => {
												return a + b.total;
											}, 0).toLocaleString()}</td>
										</tr>
										{total.map((value, index) => {
											return (
												<tr key={index}>
													<td colSpan={4}>{value.paymentMethod} total:</td>
													<td>&euro;{value.total.toLocaleString()}</td>
												</tr>
											);
										})}
									</Fragment>
								}
								</tbody>
							</table>
						</div>
					</div>
					<div className="col" >
						<iframe style={{width: '100%', height: '100%', minHeight: '1000px'}}
								srcDoc={srcDoc} id="iframe" allowFullScreen />
					</div>
				</div>
				<div className="row">
					<div className="col">
						<Pagination page={page} maxPage={maxPage} setPage={setPage} quantity={quantity} setQuantity={setQuantity}/>
					</div>
					<div className="col justify-content-between d-flex">
						<button className={"btn btn-primary"} type={"button"} onClick={(evt) => {
							const iframe = document.getElementById("iframe");
							if (iframe.srcdoc) {
								iframe.contentWindow.print();
							}
						}}>Print invoice</button>
						<div>
							<div className="form-check form-check-inline">
								<input className="form-check-input" type="checkbox" id="notExported" checked={notExported} onChange={(evt) => {
									setNotExported(evt.target.checked);
								}}/>
								<label className="form-check-label" htmlFor="notExported">Not exported only</label>
							</div>
							<button className={"btn btn-secondary"} onClick={exportInvoices} disabled={exportLoading}>Export {exportLoading && <SmallSpinner />} invoices</button>
						</div>
					</div>
				</div>

				<hr />
				{srcDoc && <Messages invoiceId={selectedInvoice}/>}
			</div>
		</SortContext.Provider>
	);
}

export default PaidInvoices;
