import {SortContext} from "../contexts/SortContext";
import {useContext} from "react";

function SortableHeader ({text, columnName}) {
	const {sortBy, asc, desc, setSortBy, setAsc, setDesc} = useContext(SortContext);

	let toReturn;

	if (columnName === sortBy) {
		if (asc) {
			toReturn = <i className="bi bi-sort-up" />;
		}
		else if (desc) {
			toReturn = <i className="bi bi-sort-down" />;
		}
		else {
			toReturn = <i className="bi bi-dash" />;
		}
	}
	else {
		toReturn = <i className="bi bi-dash" />;
	}

	return (
		<th onClick={(evt) => {
			if (sortBy !== columnName) {
				setSortBy(columnName);
				setAsc(true);
			}
			else {
				if (asc) {
					setAsc(false);
					setDesc(true);
				}
				else if (desc) {
					setDesc(false);
				}
				else {
					setAsc(true);
				}

			}
		}} style={{cursor: "pointer"}}>
			<div  className={"d-flex justify-content-between"}>
				{text}
				{toReturn}
			</div>
		</th>
	);
}

export default SortableHeader;