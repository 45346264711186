
function MessageListItem ({message}) {
	let className = "text-start";
	let style = {
		backgroundColor: "lightgrey",
		width: 'max-content'
	};

	if (message.fromAccountant) {
		className = "text-end ms-auto";
		style.backgroundColor = "lightblue";
	}

	return (
		<div className={className}>
			<span style={{color: "darkgray"}}>{message.stamp}</span>
			<div className={className + " border rounded mb-1 p-1"} style={style}>
				{message.body}
			</div>
		</div>

	);
}

export default MessageListItem;