import { useEffect, useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";

function GraphFilters({customer, setCustomer, invoiceDateFromCustomer, setInvoiceDateFromCustomer, invoiceDateToCustomer, setInvoiceDateToCustomer, }) {
	const [customersFromDb, setCustomersFromDb] = useState([]);
	const {token} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const jsonCustomers = await fetchCustomers();
				if (isMounted) {
					if (!jsonCustomers.error) {
						setCustomersFromDb(jsonCustomers.customers);
					}
				}
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			isMounted = false;
		}
	}, []);

	async function fetchCustomers() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customers', {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<div className={"p-3 bg-light border"}>
			<div className={"mb-3"}>
				<b>Filter on invoice date to the customer <input type="reset" onClick={(evt) => {
					setInvoiceDateFromCustomer('');
					setInvoiceDateToCustomer('');
				}}/></b> <br />
				<div className="row">
					<div className="col">
						<label htmlFor="from">From:</label>
						<input type="date" id={"from"} className={"form-control"} value={invoiceDateFromCustomer} onChange={(evt) => {
							if (evt.target.value > invoiceDateToCustomer && invoiceDateToCustomer) {
								evt.preventDefault();
							}
							else {
								setInvoiceDateFromCustomer(evt.target.value);
							}
						}}/>
					</div>
					<div className="col">
						<label htmlFor="to">To:</label>
						<input type="date" id={"to"} className={"form-control"} value={invoiceDateToCustomer} onChange={(evt) => {
							if (evt.target.value < invoiceDateFromCustomer && invoiceDateFromCustomer) {
								evt.preventDefault();
							}
							else {
								setInvoiceDateToCustomer(evt.target.value);
							}
						}}/>
					</div>
				</div>
			</div>

			<div>
				<label className={"form-label"} htmlFor={"graphCustomer"}>Customer('s):</label>
				<select multiple={true} className={"form-control"} id={"graphCustomer"} value={customer} onChange={(evt) => {
					setCustomer(Array.from(evt.target.selectedOptions, option => option.value));
				}}>
					{customersFromDb.map(value => {
						return <option value={value.id} key={value.id}>{value.firstName} {value.lastName}</option>;
					})}
				</select>
			</div>

		</div>
	);
}

export default GraphFilters;
