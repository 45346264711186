import MessageListItem from "./MessageListItem";

function MessageList({messages}) {
	return (
		<div>
			{messages.map((value, key) => {
				return <MessageListItem message={value} key={key}/>
			})}
		</div>
	);
}

export default MessageList;