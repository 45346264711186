import {Fragment, useContext, useEffect, useState} from "react";
import IndirectCosts from "./IndirectCosts";
import LinkedToCustomerTaskFilters from "./LinkedToCustomerTaskFilters";
import {AuthContext} from "../../contexts/AuthContext";
import SmallSpinner from "../SmallSpinner";
import Error from "../Alerts/Error";
import NotLinkedToCustomerTaskFilters from "./NotLinkedToCustomerTaskFilters";

function LinkedToCustomerTask() {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState('');
	const [results, setResults] = useState({});
	const [creationDateFrom, setCreationDateFrom] = useState('');
	const [creationDateTo, setCreationDateTo] = useState('');
	const [deliveryDateFrom, setDeliveryDateFrom] = useState('');
	const [deliveryDateTo, setDeliveryDateTo] = useState('');
	const [paymentDateFrom, setPaymentDateFrom] = useState('');
	const [paymentDateTo, setPaymentDateTo] = useState('');
	const [invoiceDateFrom, setInvoiceDateFrom] = useState('');
	const [invoiceDateTo, setInvoiceDateTo] = useState('');

	const {token} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			setLoading(true);
			setError('');
			try {
				const jsonDashboard = await fetchDashboard();
				if (isMounted) {
					if (jsonDashboard.error) {
						setError(jsonDashboard.error);
					}
					else {
						setResults(jsonDashboard.results);
					}
				}
			}
			catch (e) {
				console.error(e);
				isMounted && setError('Failed to reach the server.');
			}
			finally {
				isMounted && setLoading(false);
			}

		})();
		return () => {
			isMounted = false;
		};
	}, [creationDateFrom, creationDateTo, deliveryDateFrom, deliveryDateTo, paymentDateFrom, paymentDateTo, invoiceDateFrom, invoiceDateTo]);

	async function fetchDashboard() {
		let params = new URLSearchParams();

		params.append('linkedToCustomerTask', '0');

		if (creationDateFrom) {
			params.append('creationDateFrom', creationDateFrom);
		}
		if (creationDateTo) {
			params.append('creationDateTo', creationDateTo);
		}
		if (deliveryDateFrom) {
			params.append('deliveryDateFrom', deliveryDateFrom);
		}
		if (deliveryDateTo) {
			params.append('deliveryDateTo', deliveryDateTo);
		}
		if (paymentDateFrom) {
			params.append('paymentDateFrom', paymentDateFrom);
		}
		if (paymentDateTo) {
			params.append('paymentDateTo', paymentDateTo);
		}
		if (invoiceDateFrom) {
			params.append('invoiceDateFrom', invoiceDateFrom);
		}
		if (invoiceDateTo) {
			params.append('invoiceDateTo', invoiceDateTo);
		}

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/dashboard?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<div className={"m-2 border p-4 bg-white shadow-sm"}>
			{error && <Error message={error} /> }

			<h3 className={"text-center"}>Not linked to a customer task</h3>

			<table className={"table table-bordered table-sm table-striped m-0 border shadow-sm"}>
				<thead className={"table-secondary"}>
				<NotLinkedToCustomerTaskFilters creationDateFrom={creationDateFrom} creationDateTo={creationDateTo}
											 setCreationDateFrom={setCreationDateFrom} setCreationDateTo={setCreationDateTo}
											 deliveryDateFrom={deliveryDateFrom} deliveryDateTo={deliveryDateTo}
											 setDeliveryDateFrom={setDeliveryDateFrom} setDeliveryDateTo={setDeliveryDateTo}
											 paymentDateFrom={paymentDateFrom} paymentDateTo={paymentDateTo}
											 setPaymentDateFrom={setPaymentDateFrom} setPaymentDateTo={setPaymentDateTo}
											 invoiceDateFrom={invoiceDateFrom} invoiceDateTo={invoiceDateTo} setInvoiceDateFrom={setInvoiceDateFrom} setInvoiceDateTo={setInvoiceDateTo}/>
				</thead>
				<tbody>
					<tr>
						<th className={"text-center"}>Total value of jobs</th>
						<th>{loading || results.totalEUR === undefined ? <SmallSpinner />: <Fragment>&euro;{results.totalEUR.toLocaleString()}</Fragment>}</th>
						<th>{loading || results.totalUSD === undefined ? <SmallSpinner />: <Fragment>&#36;{results.totalUSD.toLocaleString()}</Fragment>}</th>
					</tr>
					<tr>
						<td>Jobs created but not yet delivered by the freelancers</td>
						<td>{loading || results.jobsCreatedNotDeliveredEUR === undefined ? <SmallSpinner />: <Fragment>&euro;{results.jobsCreatedNotDeliveredEUR.toLocaleString()}</Fragment>}</td>
						<td>{loading || results.jobsCreatedNotDeliveredUSD === undefined ? <SmallSpinner />: <Fragment>&#36;{results.jobsCreatedNotDeliveredUSD.toLocaleString()}</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs delivered but not yet invoiced by the freelancers</td>
						<td>{loading || results.jobsDeliveredNotInvoicedEUR === undefined ? <SmallSpinner />: <Fragment>&euro;{results.jobsDeliveredNotInvoicedEUR.toLocaleString()}</Fragment>}</td>
						<td>{loading || results.jobsDeliveredNotInvoicedUSD === undefined ? <SmallSpinner />: <Fragment>&#36;{results.jobsDeliveredNotInvoicedUSD.toLocaleString()}</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs invoiced by the freelancers but <u>NOT</u> paid by Datamundi to the freelancer yet</td>
						<td>{loading || results.jobsInvoicedNotPaidEUR === undefined ? <SmallSpinner />: <Fragment>&euro;{results.jobsInvoicedNotPaidEUR.toLocaleString()}</Fragment>}</td>
						<td>{loading || results.jobsInvoicedNotPaidUSD === undefined ? <SmallSpinner />: <Fragment>&#36;{results.jobsInvoicedNotPaidUSD.toLocaleString()}</Fragment>}</td>
					</tr>
					<tr>
						<td>Jobs invoiced by the freelancers and <u>PAID</u> by Datamundi to the freelancer</td>
						<td>{loading || results.jobsInvoicedPaidEUR === undefined ? <SmallSpinner />: <Fragment>&euro;{results.jobsInvoicedPaidEUR.toLocaleString()}</Fragment>}</td>
						<td>{loading || results.jobsInvoicedPaidUSD === undefined ? <SmallSpinner />: <Fragment>&#36;{results.jobsInvoicedPaidUSD.toLocaleString()}</Fragment>}</td>
					</tr>
				</tbody>
				<tbody>
				<tr>
					<th className={"text-center"}>CASH</th>
					<th>{loading || results.totalCashEUR === undefined ? <SmallSpinner />: <Fragment>&euro;{results.totalCashEUR.toLocaleString()}</Fragment>}</th>
					<th>{loading || results.totalCashUSD === undefined ? <SmallSpinner />: <Fragment>&#36;{results.totalCashUSD.toLocaleString()}</Fragment>}</th>
				</tr>
				</tbody>
			</table>
		</div>
	);
}

export default LinkedToCustomerTask;
