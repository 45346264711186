
function PushNotifications() {
	async function askForPermissions(evt) {
		evt.preventDefault();
		try {
			console.log(await Notification.requestPermission());
		}
		catch (e) {
			console.error(e);
		}
	}
	return(
		<form onSubmit={askForPermissions}>
			<h3>Allow push notifications</h3>
			<b>This will be used for the following purposes:</b>
			<ul className={"d-flex justify-content-center"}>
				<li>When a freelancer replies to a chat message (concerning invoices)</li>
			</ul>
			<button type={"submit"} className={"btn btn-success"}>Allow</button>
		</form>
	);
}

export default PushNotifications;