import {Fragment} from "react";

function NotLinkedToCustomerTaskFilters({creationDateFrom, setCreationDateFrom, creationDateTo, setCreationDateTo, deliveryDateFrom,
						  setDeliveryDateFrom, deliveryDateTo, setDeliveryDateTo, paymentDateFrom, setPaymentDateFrom,
						  paymentDateTo, setPaymentDateTo, invoiceDateFrom, setInvoiceDateFrom, invoiceDateTo, setInvoiceDateTo}) {
	return (
		<Fragment>
			<tr>
				<td><b>Filter on creation date by the project managers <input type="reset" onClick={(evt) => {
					setCreationDateFrom('');
					setCreationDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="from">From:</label>
							<input type="date" id={"from"} className={"form-control"} value={creationDateFrom} onChange={(evt) => {
								if (evt.target.value > creationDateTo && creationDateTo) {
									evt.preventDefault();
								}
								else {
									setCreationDateFrom(evt.target.value);
								}
							}} />
						</div>
						<div className="col">
							<label htmlFor="to">To:</label>
							<input type="date" id={"to"} className={"form-control"} value={creationDateTo} onChange={(evt) => {
								if (evt.target.value < creationDateFrom && creationDateFrom) {
									evt.preventDefault();
								}
								else {
									setCreationDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
				<td><b>Filter on delivery date by the freelancers <input type="reset" onClick={(evt) => {
					setDeliveryDateFrom('');
					setDeliveryDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="fromDeliveryDate">From:</label>
							<input type="date" id={"fromDeliveryDate"} className={"form-control"} value={deliveryDateFrom} onChange={(evt) => {
								if (evt.target.value > deliveryDateTo && deliveryDateTo) {
									evt.preventDefault();
								}
								else {
									setDeliveryDateFrom(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toDeliveryDate">To:</label>
							<input type="date" id={"toDeliveryDate"} className={"form-control"} value={deliveryDateTo} onChange={(evt) => {
								if (evt.target.value < deliveryDateFrom && deliveryDateFrom) {
									evt.preventDefault();
								}
								else {
									setDeliveryDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
			</tr>
			<tr>
				<td><b>Filter on payment date by Datamundi to the freelancers <input type="reset" onClick={(evt) => {
					setPaymentDateFrom('');
					setPaymentDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="fromPaymentDate">From:</label>
							<input type="date" id={"fromPaymentDate"} className={"form-control"} value={paymentDateFrom} onChange={(evt) => {
								if (evt.target.value > paymentDateTo && paymentDateTo) {
									evt.preventDefault();
								}
								else {
									setPaymentDateFrom(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toPaymentDate">To:</label>
							<input type="date" id={"toPaymentDate"} className={"form-control"} value={paymentDateTo} onChange={(evt) => {
								if (evt.target.value < paymentDateFrom && paymentDateFrom) {
									evt.preventDefault();
								}
								else {
									setPaymentDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
				<td><b>Filter on invoice date by freelancers to Datamundi <input type="reset" onClick={(evt) => {
					setInvoiceDateFrom('');
					setInvoiceDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="fromInvoiceDate">From:</label>
							<input type="date" id={"fromInvoiceDate"} className={"form-control"} value={invoiceDateFrom} onChange={(evt) => {
								if (evt.target.value > invoiceDateTo && invoiceDateTo) {
									evt.preventDefault();
								}
								else {
									setInvoiceDateFrom(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toInvoiceDate">To:</label>
							<input type="date" id={"toInvoiceDate"} className={"form-control"} value={invoiceDateTo} onChange={(evt) => {
								if (evt.target.value < invoiceDateFrom && invoiceDateFrom) {
									evt.preventDefault();
								}
								else {
									setInvoiceDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
			</tr>
		</Fragment>);
}

export default NotLinkedToCustomerTaskFilters;
