import * as OTPAuth from "otpauth";
import base32Encode from "base32-encode";

export function verifyTotp(totp, secret) {
    const encoder = new TextEncoder();

    return new OTPAuth.TOTP({
        algorithm: "SHA1",
        digits: 6,
        period: 30,
        secret: OTPAuth.Secret.fromBase32(base32Encode(encoder.encode(secret), 'RFC4648')), // or 'OTPAuth.Secret.fromBase32("NB2W45DFOIZA")'
    }).generate() === totp;
}