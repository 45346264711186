import {useEffect, useState, useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import SmallSpinner from "../SmallSpinner";

function PayInvoice({invoices, selectedInvoice, setRefresh}) {
	const [paymentMethods, setPaymentMethods] = useState([]);
	const [paymentMethodId, setPaymentMethodId] = useState("");
	const [loading, setLoading] = useState(false);

	const {token, setToken, setUser} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;
		(async () => {
			try {
				const jsonPaymentMethods = await fetchPaymentMethods();
				if (isMounted) {
					if (!jsonPaymentMethods.error) {
						setPaymentMethods(jsonPaymentMethods.paymentMethods);
					}
				}
			}
			catch (e) {
				console.error(e);
			}
		})();
		return () => {
			isMounted = false;
		}
	}, []);

	async function fetchPaymentMethods() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/paymentMethods', {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	async function onSubmit(evt) {
		evt.preventDefault();
		const invoice = invoices.find(value => value.id === selectedInvoice);
		if (invoice) {
			setLoading(true);

			try {
				const response = await fetch(process.env.REACT_APP_endPoint + "/v1/invoices/" + invoice.id, {
					headers: {
						'Authorization': 'Bearer ' + token,
						'Content-Type': 'application/json'
					},
					method: "PUT",
					body: JSON.stringify({
						isLsp: invoice.isLsp,
						paymentMethodId: paymentMethodId
					})
				});

				if (response.status === 200) {
					setRefresh(prevState => !prevState);
				}
			} catch (e) {
				console.error(e);
			}
			finally {
				setLoading(false);
			}
		}
	}

	return (
		<form onSubmit={onSubmit}>
			<div className={"mb-3"}>
				<label htmlFor="paymentMethod" className={"form-label"}>Payment method:</label>
				<select className={"form-select"} id={"paymentMethod"} required={true} value={paymentMethodId} onChange={(evt) => {
					setPaymentMethodId(evt.target.value);
				}}>
					<option value="">Select a payment method</option>
					{paymentMethods.map(value => {
						return <option value={value.id} key={value.id}>{value.name}</option>;
					})}
				</select>
			</div>

			<div className="d-flex justify-content-between">
				<button className={"btn btn-success"} type={"submit"} disabled={loading}>{loading ? <SmallSpinner />:'Mark invoice paid'}</button>
				<button className={"btn btn-primary"} type={"button"} onClick={(evt) => {
					const iframe = document.getElementById("iframe");
					if (iframe.srcdoc) {
						iframe.contentWindow.print();
					}
				}}>Print invoice</button>
			</div>
		</form>
	);
}

export default PayInvoice;
