
function Search({placeHolder, search, setSearch}) {
	return (
		<div>
			<input type="text" className="form-control" placeholder={placeHolder} value={search} onChange={(evt) => {
				setSearch(evt.target.value);
			}}/>
		</div>
	);
}

export default Search;