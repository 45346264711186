import {Fragment, useContext, useEffect, useState} from "react";
import MessageList from "./MessageList";
import SendMessage from "./SendMessage";
import {AuthContext} from "../../contexts/AuthContext";
import MessageListItem from "./MessageListItem";

function Messages({invoiceId}) {
	const [message, setMessage] = useState('');
	const [messages, setMessages] = useState([]);
	const [typing, setTyping] = useState(false);

	const {token, setToken, setUser, ws} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;
		let timeoutId = null;

		ws.addEventListener('message', onMessage);

		function onMessage (evt) {
			try {
				const json = JSON.parse(evt.data);
				console.log(json);
				if (json.method === "RECEIVE_MESSAGE" && json.invoiceId == invoiceId) {
					setMessages((prevState) => {
						return [...prevState, json.message];
					});
				}
				else if (json.method === "TYPING" && json.invoiceId == invoiceId) {
					setTyping(true);
					if (timeoutId) {
						clearTimeout(timeoutId);
					}
					timeoutId = setTimeout(() => {
						setTyping(false);
					}, 500);
				}
				else if (json.method === "MESSAGE_SENT" && json.invoiceId == invoiceId) {
					setMessages((prevState) => {
						return [...prevState, json.message];
					});
				}
			}
			catch (e) {
				console.error(e);
			}
		}

		(async () => {
			try {
				const {messages} = await fetchMessages();
				if (isMounted) {
					setMessages(messages);
				}
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			isMounted = false;
			ws.removeEventListener('message', onMessage);
			if (timeoutId) {
				clearTimeout(timeoutId);
			}
		}
	}, [invoiceId]);

	async function fetchMessages() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/invoices/' + invoiceId + '/messages', {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}


	async function sendMessage(evt) {
		evt.preventDefault();

		ws.send(JSON.stringify({
			method: "SEND_MESSAGE",
			invoiceId,
			body: message
		}));
		setMessage('');
	}

	return (
		<Fragment>
			<MessageList messages={messages}/>
			{typing && <MessageListItem message={{body: "Freelancer is typing...", fromAccountant: false}}/>}

			<SendMessage message={message} setMessage={setMessage} sendMessage={sendMessage} invoiceId={invoiceId}/>
		</Fragment>

	);
}

export default Messages;