import Chart from "chart.js/auto";
import {useEffect, useRef, useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import GraphFilters from "./GraphFilters";

function Graph() {
	const [invoiceDateFromCustomer, setInvoiceDateFromCustomer] = useState('');
	const [invoiceDateToCustomer, setInvoiceDateToCustomer] = useState('');
	const [customer, setCustomer] = useState([]);
	const [data, setData] = useState({labels: [], data: []});

	const myChart = useRef(null);
	const {token} = useContext(AuthContext);

	useEffect(() => {
		let chart = null;

		(async () => {
			try {
				const {results} = await fetchDashboard();
				const labels = results[0].value.map(value => value.month);

				let datasets = [];

				for (let value of results) {
					datasets.push({
						label: value.key,
						backgroundColor: value.color,
						borderColor: 'rgb(0, 0, 0)',
						data: value.value.map(value => value.total)
					});
				}
				setData({labels, data: datasets});

				const data = {
					labels: labels,
					datasets
				};

				const config = {
					type: 'bar',
					data: data,
					options: {
						maintainAspectRatio: false
					}
				};

				chart = new Chart(myChart.current, config);
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			chart && chart.destroy();
		}
	}, [customer.toString(), invoiceDateFromCustomer, invoiceDateToCustomer]);

	async function fetchDashboard() {
		let params = new URLSearchParams();

		if (invoiceDateFromCustomer) {
			params.append('invoiceDateFromCustomer', invoiceDateFromCustomer);
		}
		if (invoiceDateToCustomer) {
			params.append('invoiceDateToCustomer', invoiceDateToCustomer);
		}
		params.append('customer', customer);

		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/dashboard/graph?' + params.toString(), {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<>
			<button className={"btn btn-primary w-100"} type={"button"} data-bs-toggle="modal" data-bs-target="#graphModal">Show graph</button>

			<div className="modal fade" id="graphModal" tabIndex="-1" aria-labelledby="graphModalTitle" aria-hidden="true">
				<div className="modal-dialog modal-fullscreen">
					<div className="modal-content">
						<div className="modal-header">
							<h5 className="modal-title" id="graphModalTitle">Graph</h5>
							<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
						</div>
						<div className="modal-body">
							<GraphFilters setCustomer={setCustomer} customer={customer} invoiceDateToCustomer={invoiceDateToCustomer}
										  invoiceDateFromCustomer={invoiceDateFromCustomer} setInvoiceDateFromCustomer={setInvoiceDateFromCustomer} setInvoiceDateToCustomer={setInvoiceDateToCustomer}/>
							<div style={{height:"75%"}}>
								<canvas ref={myChart}>

								</canvas>
							</div>

							<div>
								<table className={"table table-sm table-light table-bordered"}>
									<tbody>
									<tr>
										<td>

										</td>
										{data.labels.map((value, key) => {
											return <td key={key}>{value}</td>
										})}
									</tr>
									{data.data.map((value, key) => {
										return <tr key={key}>
											<td  style={{backgroundColor: value.backgroundColor}}>{value.label}</td>
											{value.data.map((value, key) => {
												return <td key={key}>{value}</td>;
											})}
										</tr>
									})}
									</tbody>
								</table>
							</div>

						</div>
						<div className="modal-footer">
							<button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Graph;