import {Fragment, useContext} from "react";
import {JobFilterContext} from "../contexts/JobFilterContext";

function JobFilters({languagePair, setLanguagePair, workType, setWorkType, workTypes, invoiceStatus, setInvoiceStatus,
						creationDateFrom, setCreationDateFrom, creationDateTo, setCreationDateTo, invoiceDateFrom,
						invoiceDateTo, setInvoiceDateFrom, setInvoiceDateTo}) {
	const {languagePairs} = useContext(JobFilterContext);

	return (
		<Fragment>
			<div className="row">
				<div className="col justify-content-between flex-column d-flex">
					<div>
						<select className={"form-select"} value={languagePair} onChange={(evt) => {
							setLanguagePair(evt.target.value);
						}}>
							<option value="">Select a language pair</option>
							{languagePairs.map(value => {
								return <option value={value.id} key={value.id}>{value.name}</option>
							})}
						</select>
					</div>
					<div>
						<select className={"form-select"} value={invoiceStatus} onChange={(evt) => {
							setInvoiceStatus(evt.target.value);
						}}>
							<option value="">Select an invoice status</option>
							<option value="invoiced">Invoiced</option>
							<option value="notInvoiced">Not invoiced</option>
						</select>
					</div>
				</div>
				<div className={"col"}>
					<select className={"form-select "} multiple={true} value={workType} onChange={(evt) => {
						setWorkType([...evt.target.selectedOptions].filter(value => value.selected).map(value => value.value));
					}}>
						<option value="">Select a work type</option>
						{workTypes.map(value => {
							return <option value={value.id} key={value.id}>{value.name}</option>
						})}
					</select>
				</div>
			</div>
			<div className={"mt-2"}>
				<b>Filter on job creation date <input type="reset" onClick={(evt) => {
					setCreationDateFrom('');
					setCreationDateTo('');
				}}/></b>
				<div className="row">
					<div className="col">
						<label htmlFor="from">From:</label>
						<input type="date" id={"from"} className={"form-control"} value={creationDateFrom} onChange={(evt) => {
							if (evt.target.value > creationDateTo && creationDateTo) {
								evt.preventDefault();
							}
							else {
								setCreationDateFrom(evt.target.value);
							}
						}} />
					</div>
					<div className="col">
						<label htmlFor="to">To:</label>
						<input type="date" id={"to"} className={"form-control"} value={creationDateTo} onChange={(evt) => {
							if (evt.target.value < creationDateFrom && creationDateFrom) {
								evt.preventDefault();
							}
							else {
								setCreationDateTo(evt.target.value);
							}
						}}/>
					</div>
				</div>
			</div>
			<div className={"mt-2"}>
				<b>Filter on job invoice date <input type="reset" onClick={(evt) => {
					setInvoiceDateFrom('');
					setInvoiceDateTo('');
				}}/></b>
				<div className="row">
					<div className="col">
						<label htmlFor="fromInvoiceDate">From:</label>
						<input type="date" id={"fromInvoiceDate"} className={"form-control"} value={invoiceDateFrom} onChange={(evt) => {
							if (evt.target.value > invoiceDateTo && invoiceDateTo) {
								evt.preventDefault();
							}
							else {
								setInvoiceDateFrom(evt.target.value);
							}
						}} />
					</div>
					<div className="col">
						<label htmlFor="toInvoiceDate">To:</label>
						<input type="date" id={"toInvoiceDate"} className={"form-control"} value={invoiceDateTo} onChange={(evt) => {
							if (evt.target.value < invoiceDateFrom && invoiceDateFrom) {
								evt.preventDefault();
							}
							else {
								setInvoiceDateTo(evt.target.value);
							}
						}}/>
					</div>
				</div>
			</div>
		</Fragment>
	);
}

export default JobFilters;
