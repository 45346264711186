import React from "react";

function Info({message}) {
    return (
        <div className="alert alert-info" role="alert">
            {message}
        </div>
    );
}

export default Info;
