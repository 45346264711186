import {Fragment, useEffect, useState, useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";

function CustomerTaskFilters({invoiceStatus, setInvoiceStatus, creationDateFrom, setCreationDateFrom, creationDateTo,
								 setCreationDateTo, invoiceDateFrom, setInvoiceDateFrom, invoiceDateTo, setInvoiceDateTo,
								 expectedDueDateFrom, setExpectedDueDateFrom, expectedDueDateTo, setExpectedDueDateTo,
								 deliveryDateFrom, setDeliveryDateFrom, deliveryDateTo, setDeliveryDateTo,
							     customer, setCustomer, status, setStatus}) {
	const [customersFromDb, setCustomersFromDb] = useState([]);
	const {token} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const jsonCustomers = await fetchCustomers();
				if (isMounted) {
					if (!jsonCustomers.error) {
						setCustomersFromDb(jsonCustomers.customers);
					}
				}
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			isMounted = false;
		}
	}, []);

	async function fetchCustomers() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customers', {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<Fragment>
			<select className={"form-select"} value={invoiceStatus} onChange={(evt) => {
				setInvoiceStatus(evt.target.value);
			}}>
				<option value="">Select an invoice status</option>
				<option value="invoiced">Invoiced</option>
				<option value="notInvoiced">Not invoiced</option>
			</select>
			<div className={"mt-4"}>
				<b>Filter on creation date of the customer task <input type="reset" onClick={(evt) => {
					setCreationDateFrom('');
					setCreationDateTo('');
				}}/></b> <br />
				<div className={"row"}>
					<div className="col">
						<label htmlFor="fromCreationDate">From:</label>
						<input min={"2021-10-01"} type="date" id={"fromCreationDate"} className={"form-control"} value={creationDateFrom} onChange={(evt) => {
							if (evt.target.value > creationDateTo && creationDateTo) {
								evt.preventDefault();
							}
							else {
								setCreationDateFrom(evt.target.value);
							}
						}}/>
					</div>
					<div className="col">
						<label htmlFor="toCreationDate">To:</label>
						<input min={"2021-10-01"} type="date" id={"toCreationDate"} className={"form-control"} value={creationDateTo} onChange={(evt) => {
							if (evt.target.value < creationDateFrom && creationDateFrom) {
								evt.preventDefault();
							}
							else {
								setCreationDateTo(evt.target.value);
							}
						}}/>
					</div>
				</div>
			</div>
			<div className={"mt-4"}>
				<b>Filter on delivery date of the customer task <input type="reset" onClick={(evt) => {
					setDeliveryDateFrom('');
					setDeliveryDateTo('');
				}}/></b> <br />
				<div className={"row"}>
					<div className="col">
						<label htmlFor="fromDeliveryDate">From:</label>
						<input min={"2021-10-01"} type="date" id={"fromDeliveryDate"} className={"form-control"} value={deliveryDateFrom} onChange={(evt) => {
							if (evt.target.value > deliveryDateTo && deliveryDateTo) {
								evt.preventDefault();
							}
							else {
								setDeliveryDateFrom(evt.target.value);
							}
						}}/>
					</div>
					<div className="col">
						<label htmlFor="toDeliveryDate">To:</label>
						<input min={"2021-10-01"} type="date" id={"toDeliveryDate"} className={"form-control"} value={deliveryDateTo} onChange={(evt) => {
							if (evt.target.value < deliveryDateFrom && deliveryDateFrom) {
								evt.preventDefault();
							}
							else {
								setDeliveryDateTo(evt.target.value);
							}
						}}/>
					</div>
				</div>
			</div>
			<div className={"mt-4"}>
				<b>Filter on invoice date of the customer task <input type="reset" onClick={(evt) => {
					setInvoiceDateFrom('');
					setInvoiceDateTo('');
				}}/></b> <br />
				<div className={"row"}>
					<div className="col">
						<label htmlFor="fromInvoiceDate">From:</label>
						<input min={"2021-10-01"} type="date" id={"fromInvoiceDate"} className={"form-control"} value={invoiceDateFrom} onChange={(evt) => {
							if (evt.target.value > invoiceDateTo && invoiceDateTo) {
								evt.preventDefault();
							}
							else {
								setInvoiceDateFrom(evt.target.value);
							}
						}}/>
					</div>
					<div className="col">
						<label htmlFor="toInvoiceDate">To:</label>
						<input min={"2021-10-01"} type="date" id={"toInvoiceDate"} className={"form-control"} value={invoiceDateTo} onChange={(evt) => {
							if (evt.target.value < invoiceDateFrom && invoiceDateFrom) {
								evt.preventDefault();
							}
							else {
								setInvoiceDateTo(evt.target.value);
							}
						}}/>
					</div>
				</div>
			</div>
			<div className={"mt-4"}>
				<b>Filter on expected due date of the customer task <input type="reset" onClick={(evt) => {
					setExpectedDueDateFrom('');
					setExpectedDueDateTo('');
				}}/></b> <br />
				<div className={"row"}>
					<div className="col">
						<label htmlFor="fromExpectedDueDate">From:</label>
						<input min={"2021-10-01"} type="date" id={"fromExpectedDueDate"} className={"form-control"} value={expectedDueDateFrom} onChange={(evt) => {
							if (evt.target.value > expectedDueDateTo && expectedDueDateTo) {
								evt.preventDefault();
							}
							else {
								setExpectedDueDateFrom(evt.target.value);
							}
						}}/>
					</div>
					<div className="col">
						<label htmlFor="toExpectedDueDate">To:</label>
						<input min={"2021-10-01"} type="date" id={"toExpectedDueDate"} className={"form-control"} value={expectedDueDateTo} onChange={(evt) => {
							if (evt.target.value < expectedDueDateFrom && expectedDueDateFrom) {
								evt.preventDefault();
							}
							else {
								setExpectedDueDateTo(evt.target.value);
							}
						}}/>
					</div>
				</div>
			</div>

			<div className={"mt-4 row"}>
				<div className="col">
					<div className="col">
						<select multiple={true} id="status" className={"form-control"} value={customer} onChange={(evt) => {
							setCustomer(Array.from(evt.target.selectedOptions, option => option.value));
						}}>
							{customersFromDb.map(value => {
								return <option value={value.id} key={value.id}>{value.firstName} {value.lastName}</option>;
							})}
						</select>
					</div>
				</div>
				<div className="col">
					<select multiple={true} id="status" className={"form-control"} value={status} onChange={(evt) => {
						setStatus(Array.from(evt.target.selectedOptions, option => option.value));
					}}>
						<option value="Quote Analysis" >Quote Analysis</option>
						<option value="Quote Accepted">Quote Accepted</option>
						<option value="Quote Rejected">Quote Rejected</option>
						<option value="In progress" >In progress</option>
						<option value="On Hold" >On Hold</option>
						<option value="Delivered">Delivered</option>
						<option value="Closed">Closed</option>
					</select>
				</div>
			</div>
		</Fragment>
	);
}

export default CustomerTaskFilters;
