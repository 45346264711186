import LinkedToCustomerTask from "./LinkedToCustomerTask";
import NotLinkedToCustomerTask from "./NotLinkedToCustomerTask";

function Overview() {
	return (
		<div>
			<LinkedToCustomerTask />
			<NotLinkedToCustomerTask />
		</div>
	);
}

export default Overview;