import {Fragment} from "react";
import {useHistory} from "react-router-dom";

function PaidInvoiceRow({invoice, selectedInvoice}) {
	const history = useHistory();

	return (
		<tr onClick={(evt) => {
			history.push({
				pathname: "/payments/paidInvoices",
				search: "?invoiceId=" + invoice.id
			});
		}} className={selectedInvoice === invoice.id ? "table-secondary" : ""}>
			<td>{invoice.messageCount > 0 && <i className="bi bi-star-fill text-warning" />} {invoice.fullName}</td>
			<td>{invoice.email} {invoice.isLsp && <Fragment>({invoice.companyName})</Fragment>}</td>
			<td>{invoice.stamp}</td>
			<td>{invoice.paymentDate}</td>
			<td>&euro;{invoice.value.toLocaleString()}</td>
		</tr>
	);
}

export default PaidInvoiceRow;