
function JobRow({job}) {
	return (
		<tr>
			<td>{job.name}</td>
			<td>{job.workType}</td>
			<td>&euro;{job.value ? job.value.toLocaleString() : 0}</td>
			<td>{job.freelancer}</td>
			<td>{job.description}</td>
			<td>{job.invoiceDate}</td>
			<td>{job.deliveryDate}</td>
		</tr>
	);
}

export default JobRow;
