
function Pagination({page, setPage, maxPage, quantity, setQuantity}) {
	function goToPage(evt, pageToGoTo) {
		evt.preventDefault();
		setPage(pageToGoTo);
	}

	return (
		<div className={"d-flex justify-content-between align-items-center"}>
			<div className={"d-flex"}>
				<div>
					<ul className={"pagination m-0"}>
						<li className={"page-item" + (page <= 0 ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={page <= 0} onClick={(evt) => {
								goToPage(evt, 0);
							}}>&lt;&lt;</a>
						</li>
						<li className={"page-item" + (page <= 0 ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={page <= 0} onClick={(evt) => {
								goToPage(evt, page - 1);
							}}>&lt;</a>
						</li>
					</ul>
				</div>
				<div className={"ms-2"}>
					<select className={"form-select"} value={quantity} onChange={(evt) => {
						setQuantity(evt.target.value);
					}}>
						<option value="" disabled={true}>Select a quantity</option>
						<option value="2">2</option>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
			</div>
			<div>
				Page {page + 1}/{maxPage + 1}
			</div>
			<div className={"d-flex"}>
				<div className={"me-2"}>
					<select className={"form-select"} value={quantity} onChange={(evt) => {
						setQuantity(evt.target.value);
					}}>
						<option value="" disabled={true}>Select a quantity</option>
						<option value="2">2</option>
						<option value="10">10</option>
						<option value="20">20</option>
						<option value="50">50</option>
						<option value="100">100</option>
					</select>
				</div>
				<div>
					<ul className={"pagination m-0"}>
						<li className={"page-item" + (page >= maxPage ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={page >= maxPage} onClick={(evt) => {
								goToPage(evt, page + 1);
							}}>&gt;</a>
						</li>
						<li className={"page-item" + (page >= maxPage ? " disabled" : "")}>
							<a className={"page-link"} aria-disabled={page >= maxPage} onClick={(evt) => {
								goToPage(evt, maxPage);
							}}>&gt;&gt;</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
}

export default Pagination;