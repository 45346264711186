import React from "react";

function SmallSpinner() {
	return (
		<div className="spinner-grow spinner-grow-sm" role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
	)
}

export default SmallSpinner;
