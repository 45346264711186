import {Fragment, useEffect, useContext, useState} from "react";
import {AuthContext} from "../../contexts/AuthContext";
import Graph from "./Graph";

function LinkedToCustomerTaskFilters({creationDateFrom, setCreationDateFrom, creationDateTo, setCreationDateTo, deliveryDateFrom,
						  setDeliveryDateFrom, deliveryDateTo, setDeliveryDateTo, paymentDateFrom, setPaymentDateFrom,
						  paymentDateTo, setPaymentDateTo, invoiceDateFromCustomer, setInvoiceDateFromCustomer,
						  invoiceDateToCustomer, setInvoiceDateToCustomer, creationDateFromCustomerTask, setCreationDateFromCustomerTask,
						  creationDateToCustomerTask, setCreationDateToCustomerTask, invoiceDateFrom, setInvoiceDateFrom,
						  invoiceDateTo, setInvoiceDateTo, customer, setCustomer, deliveryDateFromCustomerTask, setDeliveryDateFromCustomerTask,
						  deliveryDateToCustomerTask, setDeliveryDateToCustomerTask}) {

	const [customersFromDb, setCustomersFromDb] = useState([]);
	const {token} = useContext(AuthContext);

	useEffect(() => {
		let isMounted = true;

		(async () => {
			try {
				const jsonCustomers = await fetchCustomers();
				if (isMounted) {
					if (!jsonCustomers.error) {
						setCustomersFromDb(jsonCustomers.customers);
					}
				}
			}
			catch (e) {
				console.error(e);
			}
		})();

		return () => {
			isMounted = false;
		}
	}, []);

	async function fetchCustomers() {
		const response = await fetch(process.env.REACT_APP_endPoint + '/v1/customers', {
			headers: {
				'Authorization': 'Bearer ' + token
			}
		});
		return response.json();
	}

	return (
		<Fragment>
			<tr>
				<td><b>Filter on creation date of the customer task <input type="reset" onClick={(evt) => {
					setCreationDateFromCustomerTask('');
					setCreationDateToCustomerTask('');
				}}/></b> <br />
					<div className={"row"}>
						<div className="col">
							<label htmlFor="fromCreationDate">From:</label>
							<input min={"2021-10-01"} type="date" id={"fromCreationDate"} className={"form-control"} value={creationDateFromCustomerTask} onChange={(evt) => {
								if (evt.target.value > creationDateToCustomerTask && creationDateToCustomerTask) {
									evt.preventDefault();
								}
								else {
									setCreationDateFromCustomerTask(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toCreationDate">To:</label>
							<input min={"2021-10-01"} type="date" id={"toCreationDate"} className={"form-control"} value={creationDateToCustomerTask} onChange={(evt) => {
								if (evt.target.value < creationDateFromCustomerTask && creationDateFromCustomerTask) {
									evt.preventDefault();
								}
								else {
									setCreationDateToCustomerTask(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
				<td><b>Filter on creation date by the project managers <input type="reset" onClick={(evt) => {
					setCreationDateFrom('');
					setCreationDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="from">From:</label>
							<input type="date" id={"from"} className={"form-control"} value={creationDateFrom} onChange={(evt) => {
								if (evt.target.value > creationDateTo && creationDateTo) {
									evt.preventDefault();
								}
								else {
									setCreationDateFrom(evt.target.value);
								}
							}} />
						</div>
						<div className="col">
							<label htmlFor="to">To:</label>
							<input type="date" id={"to"} className={"form-control"} value={creationDateTo} onChange={(evt) => {
								if (evt.target.value < creationDateFrom && creationDateFrom) {
									evt.preventDefault();
								}
								else {
									setCreationDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
				<td rowSpan={3}></td>
			</tr>
			<tr>
				<td><b>Filter on delivery date of the customer task <input type="reset" onClick={(evt) => {
					setDeliveryDateFromCustomerTask('');
					setDeliveryDateToCustomerTask('');
				}}/></b> <br />
					<div className={"row"}>
						<div className="col">
							<label htmlFor="fromDeliveryDateCustomerTask">From:</label>
							<input min={"2021-10-01"} type="date" id={"fromDeliveryDateCustomerTask"} className={"form-control"} value={deliveryDateFromCustomerTask} onChange={(evt) => {
								if (evt.target.value > deliveryDateToCustomerTask && deliveryDateToCustomerTask) {
									evt.preventDefault();
								}
								else {
									setDeliveryDateFromCustomerTask(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toDeliveryDateCustomerTask">To:</label>
							<input min={"2021-10-01"} type="date" id={"toDeliveryDateCustomerTask"} className={"form-control"} value={deliveryDateToCustomerTask} onChange={(evt) => {
								if (evt.target.value < deliveryDateFromCustomerTask && deliveryDateFromCustomerTask) {
									evt.preventDefault();
								}
								else {
									setDeliveryDateToCustomerTask(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
				<td><b>Filter on delivery date by the freelancers <input type="reset" onClick={(evt) => {
					setDeliveryDateFrom('');
					setDeliveryDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="fromDeliveryDate">From:</label>
							<input type="date" id={"fromDeliveryDate"} className={"form-control"} value={deliveryDateFrom} onChange={(evt) => {
								if (evt.target.value > deliveryDateTo && deliveryDateTo) {
									evt.preventDefault();
								}
								else {
									setDeliveryDateFrom(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toDeliveryDate">To:</label>
							<input type="date" id={"toDeliveryDate"} className={"form-control"} value={deliveryDateTo} onChange={(evt) => {
								if (evt.target.value < deliveryDateFrom && deliveryDateFrom) {
									evt.preventDefault();
								}
								else {
									setDeliveryDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
			</tr>
			<tr>
				<td><b>Filter on invoice date to the customer <input type="reset" onClick={(evt) => {
					setInvoiceDateFromCustomer('');
					setInvoiceDateToCustomer('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="from">From:</label>
							<input min={"2021-10-01"} type="date" id={"from"} className={"form-control"} value={invoiceDateFromCustomer} onChange={(evt) => {
								if (evt.target.value > invoiceDateToCustomer && invoiceDateToCustomer) {
									evt.preventDefault();
								}
								else {
									setInvoiceDateFromCustomer(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="to">To:</label>
							<input min={"2021-10-01"} type="date" id={"to"} className={"form-control"} value={invoiceDateToCustomer} onChange={(evt) => {
								if (evt.target.value < invoiceDateFromCustomer && invoiceDateFromCustomer) {
									evt.preventDefault();
								}
								else {
									setInvoiceDateToCustomer(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
				<td><b>Filter on payment date by Datamundi to the freelancers <input type="reset" onClick={(evt) => {
					setPaymentDateFrom('');
					setPaymentDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="fromPaymentDate">From:</label>
							<input type="date" id={"fromPaymentDate"} className={"form-control"} value={paymentDateFrom} onChange={(evt) => {
								if (evt.target.value > paymentDateTo && paymentDateTo) {
									evt.preventDefault();
								}
								else {
									setPaymentDateFrom(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toPaymentDate">To:</label>
							<input type="date" id={"toPaymentDate"} className={"form-control"} value={paymentDateTo} onChange={(evt) => {
								if (evt.target.value < paymentDateFrom && paymentDateFrom) {
									evt.preventDefault();
								}
								else {
									setPaymentDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
			</tr>
			<tr>
				<td>
					<select multiple={true} className={"form-control"} value={customer} onChange={(evt) => {
						setCustomer(Array.from(evt.target.selectedOptions, option => option.value));
					}}>
						{customersFromDb.map(value => {
							return <option value={value.id} key={value.id}>{value.firstName} {value.lastName}</option>;
						})}
					</select>
				</td>
				<td><b>Filter on invoice date by freelancers to Datamundi <input type="reset" onClick={(evt) => {
					setInvoiceDateFrom('');
					setInvoiceDateTo('');
				}}/></b> <br />
					<div className="row">
						<div className="col">
							<label htmlFor="fromInvoiceDate">From:</label>
							<input type="date" id={"fromInvoiceDate"} className={"form-control"} value={invoiceDateFrom} onChange={(evt) => {
								if (evt.target.value > invoiceDateTo && invoiceDateTo) {
									evt.preventDefault();
								}
								else {
									setInvoiceDateFrom(evt.target.value);
								}
							}}/>
						</div>
						<div className="col">
							<label htmlFor="toInvoiceDate">To:</label>
							<input type="date" id={"toInvoiceDate"} className={"form-control"} value={invoiceDateTo} onChange={(evt) => {
								if (evt.target.value < invoiceDateFrom && invoiceDateFrom) {
									evt.preventDefault();
								}
								else {
									setInvoiceDateTo(evt.target.value);
								}
							}}/>
						</div>
					</div>
				</td>
				<td><Graph /></td>
			</tr>
		</Fragment>);
}

export default LinkedToCustomerTaskFilters;
