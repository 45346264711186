import {useContext} from "react";
import {AuthContext} from "../../contexts/AuthContext";

function SendMessage({message, setMessage, sendMessage, invoiceId}) {
	const {ws} = useContext(AuthContext);

	return (
		<div>
			<form onSubmit={sendMessage}>
				<textarea className={"form-control"} placeholder={"I need more info about ..."} required={true} value={message} onChange={(evt) => {
					setMessage(evt.target.value);
					try {
						ws.send(JSON.stringify({
							method: "TYPING",
							invoiceId
						}));
					}
					catch (e) {
						console.error(e);
					}
				}}/>
				<div className="text-center">
					<button type={"submit"} className={"btn btn-success text-center mt-2"} >Send message</button>
				</div>
			</form>
		</div>
	);
}

export default SendMessage;